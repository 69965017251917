((w, d) => {
  const mq = w.matchMedia('(min-width: 992px)')
  const blocks = d.querySelectorAll('.omanaa-shift-system')

  for (const block of blocks) {
    const img = block.querySelector('img');
    const imgCnv = new Image();
    const canvas = d.createElement('canvas');
    const ctx = canvas.getContext( '2d' );
    const list = block.querySelector('ul')
    const shutters = [
      { left: 0.5, right: 0.5 },
      { left: 0.5, right: 0.5 },
      { left: 0.5, right: 0.5 },
      { left: 0.5, right: 0.5 }
    ]
    img.after(canvas);
    imgCnv.onload = () => {
      canvas.width = imgCnv.naturalWidth 
      canvas.height = imgCnv.naturalHeight
      function setGradient(idx) {
        const gradient = ctx.createLinearGradient(0,0,canvas.width, 0);
        gradient.addColorStop(0, '#fff')
        gradient.addColorStop(shutters[idx].left, '#fff')
        gradient.addColorStop(shutters[idx].left, 'transparent')
        gradient.addColorStop(shutters[idx].right, 'transparent')
        gradient.addColorStop(shutters[idx].right, '#fff')
        gradient.addColorStop(1, '#fff')
        return gradient;
      }
      function redraw(mq) {
        ctx.font = `${mq.matches ? 16 : 32}px "Source Sans Pro"`
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle'
        ctx.clearRect(0,0,canvas.width, canvas.height )
        list.querySelectorAll('li').forEach( (item,idx) => {
          let gradient = setGradient( idx );
          ctx.fillStyle = gradient
          ctx.fillRect( 0, (idx * 79) - 1 , canvas.width, 46 )
          ctx.fillStyle = '#fff'
          ctx.fillText( item.textContent, 0.5 * canvas.width, (idx * 79) + 22 )
        })
      }

      mq.addEventListener('change', redraw )
      redraw(mq)

      gsap.registerPlugin( ScrollTrigger )
      const scroll = {
        trigger: img,
        start: 'bottom bottom',
        once: true
      }
      const animation = gsap.fromTo(
        shutters,
        { left: 0.5, right: 0.5 },
        { left: 0, right: 1, duration: 3, ease: 'power1.inOut', delay: idx => 0.5 * idx, onUpdate: redraw, onUpdateParams: [mq], scrollTrigger: scroll }
      )
    }
    imgCnv.src = img.src
  }
})(window, document)